import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { theme, materialTheme } from '@hub/config'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider as MaterialProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br'

import './index.css'
import { store, persistor } from './store'
import Routes from './router'

moment.locale('pt-br')

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <MaterialProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <ToastContainer />
                        <Routes />
                    </MuiPickersUtilsProvider>
                </MaterialProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
)
